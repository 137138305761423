import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import bodyImage from './body.png';

function App() {
  const [markers, setMarkers] = useState([]);
  const [name, setName] = useState('');
  const [remark, setRemark] = useState('');
  const [datetime, setDatetime] = useState(null);
  const [errors, setErrors] = useState({});

  const addMarker = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setMarkers([...markers, { x, y }]);
  };

  const undoMarker = () => {
    setMarkers(markers.slice(0, -1));
  };

  const validateInputs = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Naam is verplicht';
    if (!datetime) newErrors.datetime = 'Datum en tijd zijn verplicht';
    if (markers.length === 0) newErrors.markers = 'Minimaal 1 marker is verplicht';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const exportToPDF = () => {
    if (!validateInputs()) return;
    const imageContainer = document.getElementById('imageContainer');
    html2canvas(imageContainer).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.text(`Naam: ${name}`, 10, 10);
      pdf.text(`Datum en tijd: ${datetime ? datetime.toString() : ''}`, 10, 20);
      
      // Split remark text into lines to handle multiline in PDF
      const remarkLines = pdf.splitTextToSize(remark, pdf.internal.pageSize.getWidth() - 20);
      pdf.text(remarkLines, 10, 30);
      
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 40, pdfWidth, pdfHeight);
      pdf.save(`${name}_${datetime ? datetime.toISOString() : 'no-date'}_marked-body.pdf`);
    });
  };

  return (
    <div className="App">
      <h1>Markeer het lichaam</h1>
      <h2>Tekenregistratie forumulier</h2>
      <div className="input-group">
        <input
          type="text"
          placeholder="Voer naam in"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {errors.name && <p className="error">{errors.name}</p>}
      </div>
      <div className="input-group">
        <DatePicker
          selected={datetime}
          onChange={(date) => setDatetime(date)}
          showTimeSelect
          dateFormat="Pp"
          placeholderText="Selecteer datum en tijd"
          timeIntervals={5}
        />
        {errors.datetime && <p className="error">{errors.datetime}</p>}
      </div>
      <div className="image-container" id="imageContainer" onClick={addMarker}>
        <img src={bodyImage} alt="Lichaamstekening" />
        {markers.map((marker, index) => (
          <div key={index} className="marker" style={{ left: marker.x, top: marker.y }}></div>
        ))}
      </div>
      {errors.markers && <p className="error">{errors.markers}</p>}
      <div style={{ marginTop: '10px', width: '300px' }}>
        <textarea
          placeholder="Opmerkingen"
          style={{ padding: "10px", fontSize: "16px", width: "100%", resize: "vertical" }}
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
          rows="4"
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        <button onClick={undoMarker}>Ongedaan maken</button>
        <button onClick={exportToPDF}>Exporteer naar PDF</button>
      </div>
    </div>
  );
}

export default App;
